import React from "react"
import { Box } from "@chakra-ui/react"
import PropTypes from "prop-types"
// import { Link as GatsbyLink } from "gatsby";
// import gsap from 'gsap';
// import TransitionLink  from 'gatsby-plugin-transition-link'
// import FadeLink from "./transitions/linkFade"
import LinkCustom from "./transitions/linkCustom"
// Copycopy code to clipboard
// import AniLink from "gatsby-plugin-transition-link/AniLink"

  
const OptionalLink = ({ children, linkNode, ...rest }) => {
    return (
        <Box
            id="conditionalLink"
            {...rest}
        >
            {linkNode && linkNode.internalLink ?
            <LinkCustom url={linkNode.internalLink.slug} effect="fade" lenght={0.8}>
                {children}
</LinkCustom>
            
                :
                <>
                    {children}
                </>
            }
        </Box>
    )
}

OptionalLink.propTypes = {
    children: PropTypes.node,
    linkNode: PropTypes.object
};

OptionalLink.defaultProps = {
    children: null,
    linkNode: null
}

export default OptionalLink
