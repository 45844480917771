import React from "react"
import Image from 'gatsby-image'

import { Heading, Box, Grid, GridItem, Center, VStack, useBreakpointValue } from "@chakra-ui/react"
import TextBlock from "../blocks/textBlock/textBlock"
import Button from "../button"
import { Link } from "gatsby"
import OptionalLink from "../optionalLink"

const PageSection = ({ section, ...rest }) => {
  const components = section.section_components

  var isResponsive = section.responsiveSections != null ? !!section.responsiveSections : true
  const displayImages = useBreakpointValue({ base: false, md: true })

  var templateRow = isResponsive ? "repeat(" + components.length + ", 1fr)" : "repeat(1, 1fr)"
  var templateColumn = isResponsive ? "repeat(1, 1fr)" : "repeat(12, 1fr)"
  var colSpan = isResponsive ? 12 : 12 / components.length
  var gap = isResponsive ? 0 : components.length > 1 ? 2 : 0


  return (
    // <Center height={section.centreTitle != null & section.centreTitle ? "70vh" : "unset"}>
    <Box
      id="pageSection"
      // textAlign="center"
      minHeight="100%"
      marginLeft={0}
      marginBottom={3}
    // {...rest}
    >
      <OptionalLink linkNode={section && section.sectionLink ? section.sectionLink : null}>
        <Center id="centerHeading" justifyContent={section.centreTitle != null & section.centreTitle ? "center" : "end"} width="100%" height={section.centreTitle != null & section.centreTitle ? "60vh" : "unset"}>
          <VStack >
            {section.project44title &&
              <Box id="headerBox" marginTop={0} ml={0}>
                <Heading id="headerBoxHeading" variant="Title" as="h1" fontSize={[40, 60, 80, 80]} paddingTop={2} marginTop={0}>
                  {section.project44title}
                </Heading>
              </Box>
            }
            <Heading as="h2" id="pageSectionHeading" display="unset" textAlign="left" variant="SectionTitle" fontSize={[16,22,24,24]} pt={[3, 3, 3, 3]} pb={1} ml={0} >
              <b>{section.title}</b>
            </Heading>
          </VStack>
        </Center>

        <Grid
          templateRows={[templateRow, templateRow, templateRow, "repeat(1, 1fr)"]}
          templateColumns={[templateColumn, templateColumn, templateColumn, "repeat(12, 1fr)"]}
          gap={[gap, gap, gap, 0]}
          height="100%"
          // textAlign="center"
          width="100%"
          paddingLeft={0}
          marginLeft={0}
          id="sGrid"
        >
          {components.map((component, index) => {

            switch (component.__typename) {

              case "ContentfulComponentButton":
                return (
                  <GridItem id="gridItemButton" key={index} rowSpan={[1]} colSpan={[colSpan, colSpan, 12 / components.length, 12 / components.length]} >
                    <Box height="65px" marginTop={10}>
                      <Link to={"/contact"}>
                        <Button buttonText={component.buttonText} />
                      </Link>
                    </Box>
                  </GridItem>
                )

              case "ContentfulComponentImageBlock":
                return (
                  <>
                    {displayImages &&

                      <GridItem id="gridItemImage" key={index} rowSpan={[1]} colSpan={[colSpan, colSpan, 12 / components.length, 12 / components.length]} >
                        <Center width="100%">
                          <Box w="100%" marginTop={5} marginBottom={5} width={["80%", "80%", "68%", "80%"]} textAlign="center">
                            <Image fluid={component.image.image.fluid} />
                          </Box>
                        </Center>
                      </GridItem>
                    }
                  </>
                )
              case "ContentfulComponentTextBlock":
                return (
                  <GridItem id="pageGridItemText" paddingLeft={0} marginLeft={0} key={index} rowSpan={[1]} colSpan={[colSpan, colSpan, 12 / components.length, 12 / components.length]} >
                    <Box id="gridItemBox" marginLeft={0} key={index} pt={[2, 2, 2, 2]} verticalAlign="top"  >

                      <TextBlock id="gridItemText" content={component} verticalAlign="top" />

                    </Box>
                  </GridItem>
                )
              default:
                return (
                  <GridItem key={index} rowSpan={1} colSpan={component.sectionWidth ? component.sectionWidth : 12 / components.length} >
                    <Box key={index} p={8}>MISSING COMPONENT TYPE</Box>
                  </GridItem>
                )
            }
          })
          }
          {/* </HStack> */}
        </Grid>
      </OptionalLink>
    </Box>
    // </Center>
  )
}

export default PageSection
